<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTracking } from '@/Composables/tracking'
import ProductsSlider from './ProductsSlider.vue'
import { Product } from '@interfaces/product.interface'

const props = defineProps<{
    products: Array<Product>,
}>()

const { t } = useI18n()
const { trackProductsListView } = useTracking()

onMounted(() => {
    trackProductsListView(props.products, {
        id: 'best-sellers',
        name: t('pages.home.best-sellers.title'),
    })
})
</script>

<template>
    <ProductsSlider :products="products" class="py-16 sm:py-24">
        <template #title>
            {{ t('pages.home.best-sellers.title') }}
        </template>
    </ProductsSlider>
</template>
